window.RealDrone = {
  locale: null,
  start: function() {
    realdrone = this;
    window.$ = $;

    $(function() {
      realdrone.startLocale();
      realdrone.startNavbar();
      realdrone.startPopover();
      // realdrone.startCarouselRoadmapScrollPosition();
      realdrone.startCarouselScrollButton();
      realdrone.startStickyTopButtons();
      realdrone.startStakingOfferOptions();
      realdrone.startTableToggle();
    })
  },
  startLocale: function() {
    const regex = /^\/(en|hu)\//;
    const match = window.location.pathname.match(regex);
    if (match) {
      this.locale = match[1];
    }
  },
  startNavbar: function() {
    let nav = document.getElementById('nav');
    let collapsible = document.getElementById('navbarSupportedContent');
    collapsible.addEventListener('show.bs.collapse', function () {
      nav.classList.remove('nav-collapsed');
      nav.classList.add('nav-expanded');
      nav.classList.remove('nav-hidden');
      nav.classList.add('nav-show');

      document.body.classList.remove('body-nav-collapsed');
      document.body.classList.add('body-nav-expanded');
      document.body.classList.remove('body-nav-hidden');
      document.body.classList.add('body-nav-show');
    });
    collapsible.addEventListener('shown.bs.collapse', function () {
      nav.classList.remove('nav-show');
      nav.classList.add('nav-shown');

      document.body.classList.remove('body-nav-show');
      document.body.classList.add('body-nav-shown');
    });
    collapsible.addEventListener('hide.bs.collapse', function () {
      nav.classList.remove('nav-expanded');
      nav.classList.add('nav-collapsed');
      nav.classList.remove('nav-shown');
      nav.classList.add('nav-hide');

      document.body.classList.remove('body-nav-expanded');
      document.body.classList.add('body-nav-collapsed');
      document.body.classList.remove('body-nav-shown');
      document.body.classList.add('body-nav-hide');
    });
    collapsible.addEventListener('hidden.bs.collapse', function () {
      nav.classList.remove('nav-hide');
      nav.classList.add('nav-hidden');

      document.body.classList.remove('body-nav-hide');
      document.body.classList.add('body-nav-hidden');
    });
    // Collapse modal main-nav menu for in-page links
    if (nav.classList.contains('main-nav')) {
      let navLinks = nav.querySelectorAll('.nav-link');
      let bsCollapse = new bootstrap.Collapse(collapsible, {toggle: false});
      navLinks.forEach((l) => {
        l.addEventListener('click', () => { bsCollapse.hide() });
      });
    }
  },
  startPopover: function() {
    let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    let popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
      popoverTriggerEl.addEventListener('show.bs.popover', function (event) {
        event.target.classList.remove('popover-hide');
        event.target.classList.add('popover-show');
      })
      popoverTriggerEl.addEventListener('hide.bs.popover', function (event) {
        event.target.classList.remove('popover-show');
        event.target.classList.add('popover-hide');
      })

      let popoverContent = document.getElementById('popover-content-' + popoverTriggerEl.dataset.attributeName)

      return new bootstrap.Popover(popoverTriggerEl, {
        container: 'body',
        trigger: 'focus',
        html: true,
        content: popoverContent
      });
    });
  },
  startCarouselRoadmapScrollPosition: function() {
    const scrollableContainers = document.querySelectorAll('.carousel-horizontal.roadmap-details');
    scrollableContainers.forEach((scrollableContainer) => {
      const currentRoadmapQuarter = 5;
      const currentColumn = scrollableContainer.querySelectorAll('.col')[currentRoadmapQuarter - 1];
      // dealying scrollTo fixes Chrome intial hash scrolling bug on fresh pageloads
      const timeout = (location.hash == '') ? 0 : 2000;
      window.setTimeout( function() {
      scrollableContainer.scrollBy({
        top: 0,
        left: currentColumn.clientWidth * currentRoadmapQuarter + 15,
        behavior: 'smooth'
        })
      }, timeout);
    });
  },
  startCarouselScrollButton: function() {
    const rightButtons = document.querySelectorAll('.carousel-control-right');
    rightButtons.forEach((button) => {
      button.addEventListener('click', (event) => {
        scrollableContainer = button.parentNode.querySelectorAll('.carousel-horizontal')[0]
        secondColumn = scrollableContainer.querySelectorAll('.col')[1]
        scrollableContainer.scrollBy({
          top: 0,
          left: secondColumn.clientWidth,
          behavior: 'smooth'
        });
        parentHash = '#' + button.parentNode.getAttribute('id')
        // persist history once
        if (window.location.hash != parentHash) {
          history.pushState(null, null, parentHash);
        }
        event.preventDefault();
      });
    });
    const leftButtons = document.querySelectorAll('.carousel-control-left');
    leftButtons.forEach((button) => {
      button.addEventListener('click', (event) => {
        scrollableContainer = button.parentNode.querySelectorAll('.carousel-horizontal')[0]
        secondColumn = scrollableContainer.querySelectorAll('.col')[1]
        scrollableContainer.scrollBy({
          top: 0,
          left: -secondColumn.clientWidth,
          behavior: 'smooth'
        });
        parentHash = '#' + button.parentNode.getAttribute('id')
        // persist history once
        if (window.location.hash != parentHash) {
          history.pushState(null, null, parentHash);
        }
        event.preventDefault();
      });
    });
  },
  startStickyTopButtons: function() {
    var html = $("html");
    $(window).scroll(function() {
      var scroll = $(window).scrollTop();
      triggerHeight = 300;
      // if (scroll >= triggerHeight) {
      //   html.removeClass(`scroll-lt-${triggerHeight}`).addClass(`scroll-gteq-${triggerHeight}`);
      // } else {
        html.removeClass(`scroll-gteq-${triggerHeight}`).addClass(`scroll-lt-${triggerHeight}`);
      // }
    });
  },
  startTableToggle: function() {
    // $('.table-toggle-checkbox').bootstrapToggle({
    //   on: 'Hide',
    //   off: 'Show'
    // });
    $('.table-toggle-checkbox').change(function() {
      if ($(this).prop('checked')) {
        $(this).siblings('table').show();
      } else {
        $(this).siblings('table').hide();
      }
    })
  },
  startStakingOfferOptions: function() {
    if (typeof window.StakingOfferOption == "undefined") return;
    if (typeof window.StakingOfferOption.collection == "undefined") return;
    if (window.StakingOfferOption.collection.length == 0) return;
    this.StakingOffers.initialize();
  },
  StakingOffers: {
    initialize: function() {
      const dis = this;
      $('#new_staking_stake .select-currency').click(function(event) {
        event.preventDefault();
        let currency = event.target.dataset.currency;
        $('#new_staking_stake .select-currency').removeClass('active')
        // if (dis.filters.currency == currency) {
        //   dis.filters.currency = null;
        // } else {
          dis.filters.currency = currency;
          $(event.target).addClass('active');
        // }
        dis.filter();
      });
      $('#new_staking_stake .select-staking-type').click(function(event) {
        event.preventDefault();
        let stakingType = event.target.dataset.stakingType
        $('#new_staking_stake .select-staking-type').removeClass('active')
        if (dis.filters.stakingType == stakingType) {
          dis.filters.stakingType = null;
        } else {
          dis.filters.stakingType = stakingType;
          $(event.target).addClass('active');
        }
        dis.filter();
      });
      $('#new_staking_stake .select-duration').click(function(event) {
        event.preventDefault();
        let durationDays = event.target.dataset.durationDays
        $('#new_staking_stake .select-duration').removeClass('active')
        // if (dis.filters.durationDays == durationDays) {
        //   dis.filters.durationDays = null;
        // } else {
          dis.filters.durationDays = durationDays;
          $(event.target).addClass('active');
        // }
        dis.filter();
      });

      $('.staking-offer-option .actions a').click(function(event) {
        event.preventDefault();
      });
      $('.staking-offer-option').click(function(event) {
        const tableRow = this;

        $(window.StakingOfferOption.collection).each(function() {
          if (this.id == tableRow.dataset.id) {
            dis.selectedOfferOption = this;
            dis.updateWithSelectedOfferOption();

            $('.staking-offer-option').removeClass('table-active');
            $(tableRow).addClass('table-active');
          }
        });
      });

      $('#staking_stake_amount').change(function(event) {
        dis.updateTotalRewards();
      });
      $('#staking_stake_amount').keyup(function(event) {
        dis.updateTotalRewards();
      });

      $('.main-wallet-max-available a.max-available').click(function(event) {
        event.preventDefault();
        $('#staking_stake_amount').val(this.dataset.maxAvailable);
      });

      if (this.filters.currency) {
        $('#new_staking_stake .currency-selector a').each(function(index) {
          if (this.dataset.currency == dis.filters.currency) {
            $(this).addClass('active');
          }
        });
      }
      if (this.filters.stakingType) {
        $('#new_staking_stake .staking-type-selector a').each(function(index) {
          if (this.dataset.stakingType == dis.filters.stakingType) {
            $(this).addClass('active');
          }
        });
      }
      if (this.filters.durationDays) {
        $('#new_staking_stake .duration-selector a').each(function(index) {
          if (this.dataset.durationDays == dis.filters.durationDays) {
            $(this).addClass('active');
          }
        });
      }
      this.filter();
    },
    amount: 0,
    updateTotalRewards: function() {
      if (this.selectedOfferOption == null) return;
      this.amount = $('#staking_stake_amount').val();
      let totalRewards = (this.amount * this.selectedOfferOption.durationPercentageYield / 100.0).toFixed(4);
      $('.total-rewards span').html(`${totalRewards} ${this.selectedOfferOption.currency}`);
    },
    filters: {
      currency: 'RDT',
      stakingType: 'locked',
      durationDays: 420
    },
    filteredOr: [],
    filteredAnd: [],
    filter: function() {
      this.filteredOr = [];
      this.filteredAnd = [];
      const dis = this;
      $(window.StakingOfferOption.collection).each(function() {
        if (
          (this.currency == dis.filters.currency) ||
          (this.stakingType == dis.filters.stakingType) ||
          (this.durationDays == dis.filters.durationDays)
        ) {
          dis.filteredOr.push(this);
        }
        if (
          (this.currency == dis.filters.currency) &&
          (this.stakingType == dis.filters.stakingType) &&
          (this.durationDays == dis.filters.durationDays)
        ) {
          dis.filteredAnd.push(this);
        }
      });

      // Filter offer option table further to offers
      const toUnselect = `#new_staking_stake .staking-offer-option`;
      let toSelect = `#new_staking_stake .staking-offer-option`;
      if (this.filters.currency) {
        toSelect = `${toSelect}[data-currency="${this.filters.currency}"]`;
      }
      if (this.filters.durationDays) {
        toSelect = `${toSelect}[data-duration-days="${this.filters.durationDays}"]`;
      }
      if (this.filters.stakingType) {
        toSelect = `${toSelect}[data-staking-type="${this.filters.stakingType}"]`;
      }
      $(toUnselect).hide();
      $(toSelect).show();

      // Filter the filtering buttons
      $('.staking-type-selector span a').hide();
      let filterSelector = new Set();
      this.filteredOr.forEach(function(e) {
        if (e.currency == dis.filters.currency) {
          filterSelector.add(`a[data-staking-type='${e.stakingType}']`);
        }
      });
      $('.staking-type-selector span').find([...filterSelector].join(',')).show();

      $('.duration-selector span a').hide();
      filterSelector.clear();
      this.filteredOr.forEach(function(e) {
        if (e.stakingType == dis.filters.stakingType) {
          filterSelector.add(`a[data-duration-days='${e.durationDays}']`);
        }
      });
      $('.duration-selector span').find([...filterSelector].join(',')).show();

      this.selectedOfferOption = this.filteredAnd[0];
      this.updateWithSelectedOfferOption();
    },
    updateWithSelectedOfferOption: function() {
      if (this.selectedOfferOption == null) {
        // Select first duration option available to default from a
        // previously available option that became unavailable
        $('#new_staking_stake .select-duration')[0].click();
      } else {
        const optionId = this.selectedOfferOption.id
        const optionSelector = `#new_staking_stake .staking_stake_staking_offer_option select`;
        $(optionSelector).val(optionId);

        // Offer table
        $('.staking-offer-option').removeClass('table-active');
        const $tableRow = $(`.staking-offer-option[data-id='${optionId}']`);
        $tableRow.addClass('table-active');

        $(`.staking-offer-option a`).removeClass('active');
        $(`.staking-offer-option[data-id='${optionId}'] a`).addClass('active');

        // Update stake details
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const stakingStarts = new Date();

        const interestStarts = new Date(stakingStarts.getTime());
        interestStarts.setDate(interestStarts.getDate() + 1);
        interestStarts.setUTCHours(0, 0, 0, 0);

        const interestEnds = new Date(interestStarts.getTime());
        interestEnds.setDate(interestEnds.getDate() + this.selectedOfferOption.durationDays);
        interestEnds.setUTCHours(0, 0, 0, 0);

        const redemptionAt = new Date(interestEnds.getTime() + this.selectedOfferOption.redemptionDurationHours * 3600 * 1000);

        const locale = (RealDrone.locale == 'hu') ? 'hu-HU' : 'en-US'

        const stakingStartsLocalized = stakingStarts.toLocaleDateString(locale, options);
        const interestStartsAtLocalized = interestStarts.toLocaleDateString(locale, options);
        const interestEndsLocalized = interestEnds.toLocaleDateString(locale, options);
        const redemptionAtLocalized = redemptionAt.toLocaleDateString(locale, options);

        $('.available-from .value').html(this.selectedOfferOption.availableFrom);
        $('.available-until .value').html(this.selectedOfferOption.availableUntil);
        $('.annual-percentage-yield .value').html(`${this.selectedOfferOption.annualPercentageYield}%`);
        $('.duration-percentage-yield .value').html(`${this.selectedOfferOption.durationPercentageYield}%`);
        $('.minimum-amount .value').html(`${this.selectedOfferOption.minimumAmount} ${this.selectedOfferOption.currency}`);
        $('.maximum-amount .value').html(`${this.selectedOfferOption.maximumAmount} ${this.selectedOfferOption.currency}`);
        $('.staking-starts-at .value').html(stakingStartsLocalized);
        $('.interest-period-starts-at .value').html(interestStartsAtLocalized);
        $('.interest-period .value').html(`${this.selectedOfferOption.interestPeriodDays}`);
        $('.duration .value').html(`${this.selectedOfferOption.durationDays}`);
        $('.interest-period-ends-at .value').html(interestEndsLocalized);
        $('.redemption-duration .value').html(`${this.selectedOfferOption.redemptionDurationHours}`);
        $('.redemption-at .value').html(redemptionAtLocalized);
        this.updateTotalRewards();
      }

      // Only for debug for now - no UI responsibility yet as button represent the filters
      $('.filters').html(JSON.stringify(this.filters));
    },
    selectedOfferOption: null
  }
}
